import React, { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import GraphiQL from 'graphiql';
import 'graphiql/graphiql.css';

import Hero from 'components/ui/Hero';
import List from 'components/ui/List';
import ScheduledPayment from 'components/profiles/Scheduled';

import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

import UserContext from 'context/UserContext';

const API_URL = 'https://payment-ninja.cardql.com/graphql';
// const API_URL = 'http://localhost:5000/graphql';

export default function Developers() {
	const { account } = useContext(UserContext);

	const Logo = () => <span>{''}</span>;

	GraphiQL.Logo = Logo;

	const { apiKey } = account;

	return (
		<div style={{ height: '100%' }}>
			<Hero
				color="primary"
				icon="code"
				title="GraphQL API Explorer"
				subtitle=" Query your live account data with GraphQL and explore the data schema
          with Documentation Explorer."
			/>
			<div className="field is-grouped is-grouped-multiline">
				<div className="control">
					<div className="tags has-addons">
						<span className="tag is-dark">Endpoint</span>
						<span className="tag is-info">{API_URL}</span>
					</div>
				</div>

				<div className="control">
					<div className="tags has-addons">
						<span className="tag is-dark">Secret Key</span>
						<span className="tag is-danger">{apiKey}</span>
					</div>
				</div>
			</div>
			<div style={{ height: '70vh' }}>
				<GraphiQL
					style={{ height: '100vh' }}
					fetcher={async (graphQLParams) => {
						const data = await fetch(API_URL, {
							method: 'POST',
							headers: {
								Accept: 'application/json',
								'Content-Type': 'application/json',
								Authorization: `Bearer ${apiKey}`
							},
							body: JSON.stringify(graphQLParams)
						});
						return data.json().catch(() => data.text());
					}}
				/>
			</div>
		</div>
	);
}
