import React from 'react';

import avatar from 'assets/images/avatar_sq.png';
export default function Spinner() {
	return (
		<section className="hero is-medium">
			<div className="hero-body">
				<div className="container">
					<div className="columns is-centered">
						<div className="column is-one-third has-text-centered">
							{/* <img alt="Payment.Ninja" src={avatar} width="100" />
							<br /> */}
							<p className="title is-4">Loading...</p>
							<progress className="progress is-dark is-small" max="100" />
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
