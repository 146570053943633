import React from 'react';
import { Fade } from 'react-slideshow-image';

import invoices from 'assets/images/slideshow/invoices.png';
import customers from 'assets/images/slideshow/customers.png';
import developers from 'assets/images/slideshow/developers.png';
import payments from 'assets/images/slideshow/payments.png';
import dashboard from 'assets/images/slideshow/dashboard.png';

const fadeImages = [ payments, invoices, customers, developers, dashboard ];

const fadeProperties = {
	duration: 10000,
	transitionDuration: 1000,
	infinite: true,
	arrows: true,
	indicators: true
	// onChange: (oldIndex, newIndex) => {
	// 	console.log(`fade transition from ${oldIndex} to ${newIndex}`);
	// }
};

const Slideshow = () => (
	<div style={{ width: 400, height: 500 }}>
		<Fade {...fadeProperties}>
			<div className="each-fade">
				<div className="image-container" style={{ width: 400 }}>
					<img src={fadeImages[0]} alt="Process payments online, in-store and on mobile" />
				</div>
				<h2 className="subtitle">
					Process payments online, in-store and on mobile at the same super-low rates.
				</h2>
			</div>
			<div className="each-fade">
				<div className="image-container" style={{ width: 400 }}>
					<img src={fadeImages[1]} alt="Send smart invoices, get paid faster!" />
				</div>
				<h2 className="subtitle">Send smart invoices, get paid faster!</h2>
			</div>
			<div className="each-fade">
				<div className="image-container" style={{ width: 400 }}>
					<img src={fadeImages[2]} alt="Know where you stand with each of your customers" />
				</div>
				<h2 className="subtitle">Know where you stand with each of your customers, in real-time.</h2>
			</div>
			<div className="each-fade">
				<div className="image-container" style={{ width: 400 }}>
					<img src={fadeImages[3]} alt="Improve your cashflow with automatic recurring payments" />
				</div>
				<h2 className="subtitle">Improve your cashflow with automatic recurring payments.</h2>
			</div>
			<div className="each-fade">
				<div className="image-container" style={{ width: 400 }}>
					<img src={fadeImages[4]} alt="Improve your cashflow with automatic recurring payments" />
				</div>
				<h2 className="subtitle">Keep an eye on your sales with Real-time Dashboard</h2>
			</div>
		</Fade>
	</div>
);

export default Slideshow;
