import React, { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import Downshift from 'downshift';

import customerName from 'utils/customerName';

import UserContext from 'context/UserContext';

export default function CustomerLookup({ field, form, icon, label, placeholder, size }) {
	const [ loading, setLoading ] = useState(false);

	return (
		<Downshift
			initialInputValue={field.value.email}
			itemToString={(item) => customerName(item)}
			onChange={(item) => form.setFieldValue(field.name, item)}
		>
			{({ getInputProps, getItemProps, getLabelProps, inputValue, selectedItem, highlightedIndex, isOpen }) => (
				<div>
					{/* TODO: Input Component */}
					<div className="field">
						<label {...getLabelProps()} className={`label is-${size}`}>
							{label}
						</label>
						<div
							className={`control is-${size} ${icon && 'has-icons-left'} has-icons-right ${loading &&
								'is-loading'}`}
						>
							<input
								name={field.name}
								{...getInputProps()}
								aria-haspopup="true"
								aria-controls="suggestions"
								placeholder={placeholder}
								className={`input is-${size} is-${form.touched[field.name] &&
									form.errors[field.name] &&
									'danger'}`}
							/>

							{icon && (
								<span className={`icon is-${size} is-left`}>
									<i className={`fas fa-${icon}`} />
								</span>
							)}

							{form.touched[field.name] &&
							!form.errors[field.name] && (
								<span className={`icon is-${size} is-right`}>
									<i className={`fas fa-check`} />
								</span>
							)}

							{form.touched[field.name] &&
							form.errors[field.name] && (
								<span className={`icon is-${size} is-right`}>
									<i className={`fas fa-times`} />
								</span>
							)}
						</div>

						{form.touched[field.name] &&
						form.errors[field.name] && <p className="help is-danger">{form.errors[field.name]}</p>}
					</div>
					{/* TODO END */}

					<div className={`dropdown ${isOpen && 'is-active'}`}>
						<div className="dropdown-menu" id="suggestions" role="menu">
							<div className="dropdown-content">
								<Lookup
									setLoading={setLoading}
									{...{
										inputValue,
										selectedItem,
										highlightedIndex,
										getItemProps
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</Downshift>
	);
}

function Lookup({ inputValue, selectedItem, highlightedIndex, getItemProps, setLoading }) {
	const { headers } = useContext(UserContext);

	const { data, loading } = useQuery(CUSTOMER_SEARCH, {
		variables: { query: inputValue }
	});

	setLoading(loading);

	// TODO: Not hightliting selection with keyboard

	if (data && data.consumerSearch) {
		return (
			<ul>
				{data.consumerSearch.map((item, index) => (
					<li
						{...getItemProps({
							item,
							index,
							key: item.id
						})}
						className="dropdown-item"
					>
						<a className="dropdown-item">
							<p className="is-size-4 has-text-weight-bold">
								{item.firstName && item.lastName ? `${item.firstName} ${item.lastName}` : item.email}
							</p>
							<p className="is-size-6">{item.email}</p>
						</a>
					</li>
				))}
			</ul>
		);
	}
	return null;
}

const CUSTOMER_SEARCH = gql`
	query ConsumerSearch($query: String!) {
		consumerSearch(query: $query) {
			id
			firstName
			lastName
			email
			cards {
				id
				status
			}
		}
	}
`;
