import React from 'react';
import { Link } from '@reach/router';

import logo from 'assets/images/logo.png';

function Menu({ title, children }) {
	return (
		<aside className="menu is-dark">
			{title && <p className="menu-label">{title}</p>}
			<ul className="menu-list">{children}</ul>
		</aside>
	);
}

function MenuItem({ extended, name, icon, route, close }) {
	if (extended) {
		return (
			<li>
				<Link
					to={route}
					onClick={close}
					getProps={({ isCurrent }) => {
						return isCurrent ? { className: 'is-active' } : null;
					}}
				>
					<span className="icon">
						<i className={`fas fa-${icon}`} />
					</span>{' '}
					<strong>{name}</strong>
				</Link>
			</li>
		);
	} else
		return (
			<li className="has-text-centered">
				<Link
					to={route}
					getProps={({ isCurrent }) => {
						return isCurrent ? { className: 'has-text-primary' } : null;
					}}
				>
					<span className="icon is-large">
						<i className={`fas fa-${icon} fa-lg`} />
					</span>
					<p className="is-size-7 has-text-weight-semibold">{name}</p>
				</Link>
			</li>
		);
}

export default function SidePanel({ extendedMenu, extendMenu }) {
	return (
		<aside className="menu is-dark sticky">
			<div>
				<div className="is-hidden-mobile">
					<ul className="menu-list">
						<MenuItem name="Dashboard" icon="chart-pie" route="/" />
						<MenuItem name="Transactions" icon="stream" route="/activity" />
						<MenuItem name="Customers" icon="user-friends" route="/customers" />
						{/* <MenuItem name="Invoices" icon="paperclip" route="/invoices" /> */}
						<MenuItem name="Recurring" icon="clock" route="/scheduled" />
						<MenuItem name="GraphQL" icon="code" route="/developers" />
					</ul>
				</div>
				<ExtendedMenu extendedMenu={extendedMenu} extendMenu={extendMenu} />
			</div>
		</aside>
	);
}

function ExtendedMenu({ extendedMenu, extendMenu }) {
	return (
		<div className={`navigation-view ${extendedMenu ? 'is-active' : ''}`} id="myNavigationView">
			<div className="navbar-brand">
				<div className="navbar-start">
					<Link className="navbar-item" to="/">
						<img alt="Payment.Ninja" witdth="200" src={logo} />
					</Link>
				</div>
				<div className="navbar-end">
					<div className="navbar-item">
						<button className="delete is-medium" onClick={() => extendMenu(!extendedMenu)} />
					</div>
				</div>
			</div>
			<br />
			<br />
			<Menu>
				<MenuItem
					extended
					name="Dashboard"
					icon="chart-pie"
					route="/"
					close={() => extendMenu(!extendedMenu)}
				/>
				<MenuItem
					extended
					name="Customers"
					icon="user-friends"
					route="/customers"
					close={() => extendMenu(!extendedMenu)}
				/>
				<MenuItem
					extended
					name="Transactions"
					icon="credit-card"
					route="/activity"
					close={() => extendMenu(!extendedMenu)}
				/>
				{/* <MenuItem
					extended
					name="Invoices"
					icon="paperclip"
					route="/invoices"
					close={() => extendMenu(!extendedMenu)}
				/> */}
				<MenuItem
					extended
					name="Scheduled"
					icon="clock"
					route="/scheduled"
					close={() => extendMenu(!extendedMenu)}
				/>
				<MenuItem
					extended
					name="Developers"
					icon="code"
					route="/developers"
					close={() => extendMenu(!extendedMenu)}
				/>
			</Menu>
		</div>
	);
}
