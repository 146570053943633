import React, { useContext, useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import Navbar from 'components/ui/Navbar';
import { Box, Button, ButtonGroup } from 'components/ui/bulma/elements';
import { Input } from 'components/ui/bulma/form';

import UserContext from 'context/UserContext';

export default function AccountSetup() {
	const { user, account } = useContext(UserContext);

	const [ userProps, setUserProps ] = useState();
	const [ merchantProps, setMerchantProps ] = useState();

	useEffect(
		() => {
			if (userProps && merchantProps) {
				window.analytics.track('Sign Up', {
					platform: 'payment-ninja',
					accountID: account.id,
					name: account.name,
					userID: user.uid,
					email: user.email
				});

				window.analytics.identify(user.uid, {
					name: `${userProps.firstName} ${userProps.lastName}`,
					phone: userProps.phone,
					company: {
						id: merchantProps.id,
						name: merchantProps.name,
						website: merchantProps.url,
						volume: merchantProps.volume,
						status: 'onboarding'
					}
				});
			}
		},
		[ userProps, merchantProps ]
	);

	const [ onboardAccount ] = useMutation(ONBOARD_MERCHANT, {
		refetchQueries: [ 'CurrentUser' ]
	});
	const [ updateUser ] = useMutation(UPDATE_USER);

	return (
		<div>
			<Navbar />
			<div className="columns is-mobile is-centered">
				<div className="column is-half-desktop">
					<Box>
						<h1 className="title">About your business</h1>
						<Formik
							initialValues={{
								name: '',
								url: '',
								volume: '',
								fullName: '',
								phone: ''
							}}
							validationSchema={AccountSchema}
							onSubmit={({ name, fullName, phone, url, volume }, { setSubmitting, setStatus }) => {
								setSubmitting(true);

								const merchantProps = {
									id: account.id,
									name,
									url,
									volume
								};

								const [ firstName, lastName ] = fullName.split(' ');

								const userProps = {
									uid: user.uid,
									firstName,
									lastName,
									phone
								};

								updateUser({ variables: { userProps } });
								onboardAccount({ variables: { merchantProps } });

								setUserProps(userProps);
								setMerchantProps(merchantProps);

								setSubmitting(false);
							}}
						>
							{({
								status,
								isSubmitting,
								handleSubmit,
								handleChange,
								handleBlur,
								values,
								touched,
								errors
							}) => (
								<Form>
									<Field
										name="name"
										label="Business Name"
										icon="store"
										size="medium"
										component={Input}
										placeholder="Business Name"
									/>
									<Field
										name="url"
										label="Website"
										icon="globe"
										size="medium"
										component={Input}
										placeholder="https://"
									/>
									<Field
										name="volume"
										label="Monthly Sales"
										icon="dollar-sign"
										size="medium"
										component={Input}
										placeholder="Monthly Sales"
									/>
									<hr />
									<Field
										name="fullName"
										label="Your Full Name"
										icon="user"
										size="medium"
										component={Input}
										placeholder="Your Full Name"
									/>

									<Field
										name="phone"
										label="Mobile Phone Number"
										icon="phone"
										size="medium"
										component={Input}
										mask="(999) 999 9999"
										placeholder="Phone Number"
									/>

									<p className="is-size-7">
										Your personal information is protected. We will not sell it or spam you. We will
										use your mobile phone for identificaion and important TEXT messages only.
									</p>
									<hr />

									<Button type="submit" block size="medium" icon="check-circle">
										Finish
									</Button>
								</Form>
							)}
						</Formik>
					</Box>
				</div>
			</div>
		</div>
	);
}

const AccountSchema = Yup.object().shape({
	name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
	url: Yup.string().url('Invalid website address').required('Please enter your website address'),
	volume: Yup.number().required('Your total sales per month'),
	fullName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('What is your name?'),
	phone: Yup.string().required(
		'We need your mobile number to send you the important TEXT messages about your account.'
	)
});

const ONBOARD_MERCHANT = gql`
	mutation OnboardMerchant($merchantProps: MerchantProps) {
		onboardMerchant(merchantProps: $merchantProps) {
			id
			name
			status
		}
	}
`;

const UPDATE_USER = gql`
	mutation UpdateUser($userProps: UserProps) {
		updateUser(userProps: $userProps) {
			id
			firstName
			lastName
		}
	}
`;
