import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Router } from '@reach/router';

import { ModalProvider } from 'react-modal-hook';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseAppAuth } from 'services/firebase';

import Layout from 'components/ui/Layout';
import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

// import CampaignSetup from 'components/CampaignWizard';

// **** Pages ****
import Dashboard from 'pages/Dashboard';
import Customers from 'pages/Customers';
import Activity from 'pages/Activity';
import Orders from 'pages/Orders';
import Invoices from 'pages/Invoices';
import Scheduled from 'pages/Scheduled';
import Developers from 'pages/Developers';

// **** Profiles ****
import CustomerProfile from 'components/profiles/Customer';
import InvoiceProfile from 'components/profiles/Invoice';
import ScheduledProfile from 'components/profiles/Scheduled';
import TransactionProfile from 'components/profiles/Activity';

// **** Forms ****
import AccountSetup from 'components/forms/AccountSetup';
import ActivateAccount from 'components/forms/Activate';

import UserContext from 'context/UserContext';

export default function Account() {
	const [ user ] = useAuthState(firebaseAppAuth);

	const { loading, data, error } = useQuery(CURRENT_USER);

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;




	if (data?.merchants?.length > 0) {
		const [ merchant ] = data.merchants;


		return (
			<UserContext.Provider value={{ user, merchant }}>
				<ModalProvider>
					<Router>
						<Layout path="/">
							<Dashboard path="/" />
							<Dashboard path="/welcome" />
							<Dashboard path="/signup" />

							<Activity path="activity" />
							<TransactionProfile path="activity/:itemID" />

							<Customers path="customers" />
							<CustomerProfile path="customers/:itemID" />

							<Orders path="orders" />

							<Invoices path="invoices" />

							<InvoiceProfile path="invoices/:itemID" />

							<Scheduled path="scheduled" />
							<ScheduledProfile path="scheduled/:itemID" />

							<Developers path="/developers" />
							<ActivateAccount path="/activate" />
						</Layout>
						<Layout default />
					</Router>
				</ModalProvider>
			</UserContext.Provider>
		);
	}

	return null;
}

// export default gql`
//   mutation ActivateMerchant($merchantProps: MerchantProps) {
//     activateMerchant(merchantProps: $merchantProps) {
//       id
//       status
//     }
//   }
// `;

// export default gql`
// 	mutation OnboardMerchant($merchantProps: MerchantProps) {
// 		onboardMerchant(merchantProps: $merchantProps) {
// 			id
// 		}
// 	}
// `;

// export default gql`
//   mutation ActivateMerchant($merchantProps: MerchantProps) {
//     activateMerchant(merchantProps: $merchantProps) {
//       id
//       isLive
//     }
//   }
// `;

// export default gql`
//   mutation UpdateMerchant($merchantProps: MerchantProps) {
//     updateMerchant(merchantProps: $merchantProps) {
//       id
//     }
//   }
// `;

const CURRENT_USER = gql`
	query CurrentUser {
		merchants {
			id
			name
			key
			apiKey
			status
			isLive
			volume
			url
			status
			phone
		}
	}
`;
