import React, { useContext, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import { Link } from '@reach/router';

import UserAvatar from 'react-user-avatar';

import Toolbar from 'components/ui/Toolbar';
import List from 'components/ui/List';
import StatusTag from 'components/ui/StatusTag';
import { Box, Icon } from 'components/ui/bulma/elements';

import formatDate from 'utils/formatDate';
import setColor from 'utils/setColor';
import statusIcon from 'utils/statusIcon';
import customerName from 'utils/customerName';

import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

import UserContext from 'context/UserContext';

export default function Invoice({ itemID }) {
	const { headers } = useContext(UserContext);
	const [ item, setItem ] = useState(itemID);
	const { data, loading, error } = useQuery(INVOICE_DETAILS, {
		variables: { id: itemID }
	});

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	const buttons = [ { action: setItem, icon: 'search-plus' } ];

	const {
		id,
		allowInstallments,
		dueDate,
		tax,
		taxRate,
		total,
		amount,
		status,
		consumer,
		items,
		payments
	} = data.invoice;

	return (
		<Box>
			<article className={`message is-${setColor(status)}`}>
				<div className="message-body">
					<StatusTag type="invoice" item={data.invoice} size="medium" />
					<br />
					<h1 className="title is-1">
						<small>$</small>
						{Number(amount).toFixed(2)}
					</h1>
					<h2 className="subtitle">{formatDate(dueDate)}</h2>
				</div>
			</article>
			<hr />
			<Box>
				<table className="table is-striped is-hoverable is-fullwidth">
					<thead>
						<tr>
							<th>Item</th>
							<th>Price</th>
							<th>Qty</th>
							<th>Subtotal</th>
						</tr>
					</thead>
					<tbody>
						{items.map(({ id, item, price, qty }) => (
							<tr key={id}>
								<td>{item}</td>
								<td>${price}</td>
								<td>{qty}</td>
								<td>${Number(qty * price).toFixed(2)}</td>
							</tr>
						))}
					</tbody>
				</table>
			</Box>
			<Box className="is-pulled-right">
				<p className="title">Due: ${amount}</p>
				<p className="subtitle">
					Total ${total}
					<br />
					Tax({taxRate}%) ${tax}
				</p>
			</Box>
			<List type="activity" view="table" data={payments} actions={[ setItem ]} />

			{/* <nav className="level">
				<div className="level-left">
					<div className="level-item">
						<div>
							<span className="title is-1">
								<small>$</small>
								{Number(amount).toFixed(2)}
							</span>
							<br />
							<br />
							<p>
								<strong>{formatDate(date)}</strong>
							</p>
						</div>
					</div>
				</div>

				<div className="level-right">
					<div className="level-item">
						<figure className="image is-64x64">
							<UserAvatar size="64" name={customerName(consumer)} colors={[ '#ccc', '#aaa', '#ddd' ]} />
						</figure>
					</div>
					<div className="level-item">
						<Link to={`/customers/${consumer.id}`}>
							<p className="title is-5">{customerName(consumer)}</p>
						</Link>
					</div>
				</div>
			</nav> */}
			{/* <Toolbar buttons={buttons} color="primary" /> */}
		</Box>
	);
}

const INVOICE_DETAILS = gql`
	query InvoiceDetails($id: ID!) {
		invoice(id: $id) {
			id
			allowInstallments
			items {
				id
				item
				qty
				price
				description
			}
			consumer {
				id
				firstName
				lastName
				email
				phone
			}
			payments {
				id
				date
				amount
				currency
				reference
				status
				card {
					id
					brand
					last4
				}
				consumer {
					id
					firstName
					lastName
					email
					phone
				}
			}
			description
			status
			total
			tax
			taxRate
			amount
			dueDate
		}
	}
`;
