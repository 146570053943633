import './App.sass';
import React, { useEffect, useState } from 'react';

import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseAppAuth } from 'services/firebase';

import Auth from 'pages/AuthPage';
import Account from 'components/Account';

import Icons from 'utils/icons';

import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

import analytics from 'react-segment';

const SEGMENT_KEY = process.env.REACT_APP_SEGMENT_KEY;

analytics.default.load(SEGMENT_KEY);

// const API_URL = process.env.REACT_APP_API_ROOT_URL;
const DEV = 'https://hdx4mtba5j.execute-api.us-east-1.amazonaws.com/dev/graphql';
// const PROD = 'https://t3idxh9d27.execute-api.us-east-1.amazonaws.com/prod/graphql';

Icons();

export default function App() {
	const [ token, setToken ] = useState();
	const [ user, loading, error ] = useAuthState(firebaseAppAuth);

	useEffect(
		() => {
			const getToken = async () => {
				const token = await firebaseAppAuth.currentUser.getIdToken();
				setToken(token);
			};

			if (user) getToken();
		},
		[ user ]
	);

	// useEffect(
	// 	() => {
	// 		if (user)
	// 			window.analytics.identify(user.uid, {
	// 				name: user.displayName,
	// 				email: user.email,
	// 				createdAt: user.metadata.creationTime,
	// 				platform: 'payment-ninja'
	// 			});
	// 	},
	// 	[ user ]
	// );

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	const client = new ApolloClient({
		cache: new InMemoryCache(),
		link: new HttpLink({
			headers: {
				authorization: token
			},
			uri: '/.netlify/functions/graphql'
		})
	});

	return <ApolloProvider client={client}>{token ? <Account /> : <Auth />}</ApolloProvider>;
}
