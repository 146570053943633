import React, { useContext } from 'react';
import { gql, useMutation } from '@apollo/client';

import { Formik, Form, FastField } from 'formik';
import PaymentCard from 'react-payment-card-component';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { css } from 'styled-components';
import numeral from 'numeral';
import * as Yup from 'yup';

import { Box, Button } from 'components/ui/bulma/elements';
import { Input } from 'components/ui/bulma/form';

import Modal from 'components/ui/Modal';
import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

import UserContext from 'context/UserContext';

export default function PaymentForm({ customer, save, onClose }) {
	const { account: { key }, headers } = useContext(UserContext);

	const [ createPayment, { data, loading, error } ] = useMutation(CREATE_PAYMENT, {
		refetchQueries: [ 'Account', 'Payments', 'ConsumerDetails' ]
	});

	const [
		saveCard,
		{ data: saveCardData, loading: saveCardLoading, error: saveCardError }
	] = useMutation(SAVE_CARD, {
		refetchQueries: [ 'Account', 'ConsumerDetails' ]
	});

	const {
		meta,
		wrapperProps,
		getCardImageProps,
		getCardNumberProps,
		getExpiryDateProps,
		getCVCProps
	} = usePaymentInputs();

	if (error) return <Error />;
	if (loading)
		return (
			<div className="columns is-mobile is-centered">
				<div className="column is-half">
					<Box>
						<Spinner />
					</Box>
				</div>
			</div>
		);

	if (data && data.createPayment)
		return (
			<div className="container">
				<div className="columns is-centered">
					<div className="column is-half has-text-centered">
						<Box>
							{data.createPayment.status === 'approved' ? (
								<span className="icon is-large has-text-success">
									<i className="fas fa-check-circle fa-3x" />
								</span>
							) : (
								<span className="icon is-large has-text-danger">
									<i className="fas fa-times-circle fa-3x" />
								</span>
							)}
							<br />
							<h1 className="title is-1">
								${data.createPayment.amount} {data.createPayment.status}
							</h1>
							<button onClick={onClose} className="button is-large is-fullwidth">
								Close
							</button>
						</Box>
					</div>
				</div>
			</div>
		);

	if (saveCardData)
		return (
			<div className="container">
				<div className="columns is-centered">
					<div className="column is-half has-text-centered">
						<Box>
							<span className="icon is-large has-text-success">
								<i className="fas fa-check-circle fa-3x" />
							</span>

							<br />
							<h1 className="title is-3">The card has been saved.</h1>
							<button onClick={onClose} className="button is-large is-fullwidth">
								Close
							</button>
						</Box>
					</div>
				</div>
			</div>
		);

	return (
		<Formik
			initialValues={{
				email: customer ? customer.email : '',
				amount: save ? '1' : '',
				description: save ? 'save card' : '',
				cardNumber: '',
				expiryDate: '',
				cvc: ''
			}}
			validationSchema={validationSchema}
			onSubmit={({ email, amount, description, cardNumber, expiryDate, cvc }) => {
				const exp = expiryDate.split('/');
				const paymentProps = {
					key,
					email,
					amount: amount.toString(),
					currency: 'USD',
					card: cardNumber,
					expMM: exp[0].trim(),
					expYYYY: `20${exp[1].trim()}`,
					cvc
				};

				if (save) {
					saveCard({
						variables: {
							paymentProps
						}
					});
				} else {
					createPayment({
						variables: {
							paymentProps
						}
					});
				}
			}}
		>
			{({ isSubmitting, values }) => (
				<div className="columns is-vcentered">
					<div className="column is-narrow is-hidden-mobile">
						<PaymentCard
							bank="default"
							model="normal"
							type=""
							brand={meta.cardType && meta.cardType.type}
							number={values.cardNumber || ''}
							cvv={values.cvc || ''}
							holderName={values.email}
							expiration={values.expiryDate || ''}
							flipped={meta.focused === 'cvc' ? true : false}
						/>
					</div>

					<div className="column is-narrow">
						<Modal icon="credit-card" title={save ? 'Save card' : 'Process payment'} onClose={onClose}>
							<Form>
								{!customer && (
									<FastField
										name="email"
										label="Customer's Email"
										icon="user"
										size="large"
										component={Input}
										placeholder="Email"
									/>
								)}
								{save || (
									<FastField
										type="number"
										name="amount"
										label="Total Amount"
										icon="dollar-sign"
										size="large"
										component={Input}
										placeholder="Amount"
									/>
								)}
								<div className="field">
									<label className="label is-large">Card Info</label>
									<div className="control">
										<PaymentInputsWrapper {...wrapperProps} styles={style}>
											<svg {...getCardImageProps({ images })} />
											<FastField name="cardNumber">
												{({ field }) => (
													<input
														{...getCardNumberProps({
															onBlur: field.onBlur,
															onChange: field.onChange
														})}
													/>
												)}
											</FastField>
											<FastField name="expiryDate">
												{({ field }) => (
													<input
														{...getExpiryDateProps({
															onBlur: field.onBlur,
															onChange: field.onChange
														})}
													/>
												)}
											</FastField>
											<FastField name="cvc">
												{({ field }) => (
													<input
														{...getCVCProps({
															onBlur: field.onBlur,
															onChange: field.onChange
														})}
													/>
												)}
											</FastField>
										</PaymentInputsWrapper>
									</div>
								</div>
								{save || (
									<FastField
										name="description"
										label="What is it for?"
										icon="info-circle"
										size="large"
										component={Input}
										placeholder="Example: Monthly service"
									/>
								)}
								<hr />
								<Button type="submit" block size="large" icon="check-circle" disabled={isSubmitting}>
									{save ? (
										'Save card'
									) : (
										`Charge ${values.amount && numeral(values.amount).format('$0,0.00')} now`
									)}
								</Button>
							</Form>
						</Modal>
					</div>
				</div>
			)}
		</Formik>
	);
}

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email').required('Please enter the customers email!'),
	amount: Yup.number()
		.positive('Must be a positive number!')
		.min(1, 'Minimum $1')
		.max(10000, 'Limit is $10,000')
		.required('Please enter the transaction amount!'),
	description: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Please enter a description!')
});

const style = {
	fieldWrapper: {
		base: css`margin-bottom: 1rem;`
	},
	inputWrapper: {
		base: css`font-size: 1.3rem;`,
		errored: css`border-color: red;`,
		focused: css`
			border-color: unset;
			box-shadow: unset;

			outline-offset: 2px;
		`
	},
	input: {
		base: css`color: unset;`,
		errored: css`color: red;`,
		cardNumber: css`width: 15rem;`,
		expiryDate: css`width: 10rem;`,
		cvc: css`width: 5rem;`
	},
	errorText: {
		base: css`color: red;`
	}
};

const CREATE_PAYMENT = gql`
	mutation CreatePayment($paymentProps: PaymentProps) {
		createPayment(paymentProps: $paymentProps) {
			id
			amount
			currency
			status
			authCode
			responseCode
			retrievalRef
			responseText
			isLive
		}
	}
`;

const SAVE_CARD = gql`
	mutation CreatePaymentMethod($paymentProps: PaymentProps) {
		createPaymentMethod(paymentProps: $paymentProps) {
			id
			token
			brand
			bin
			last4
			status
		}
	}
`;
