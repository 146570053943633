import React, { useContext, useRef, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';

import { useModal } from 'react-modal-hook';
import { Link } from '@reach/router';
import ReactToPrint from 'react-to-print';
import UserAvatar from 'react-user-avatar';

import Toolbar from 'components/ui/Toolbar';
import List from 'components/ui/List';
import StatusTag from 'components/ui/StatusTag';

import ScheduleForm from 'components/forms/ScheduleForm';

import { Box, Icon } from 'components/ui/bulma/elements';
import { Level, Left, Right, Item } from 'components/ui/bulma/layout';

import formatDate from 'utils/formatDate';
import setColor from 'utils/setColor';
import statusIcon from 'utils/statusIcon';
import customerName from 'utils/customerName';

import Spinner from 'components/ui/Spinner';
import Error from 'components/ui/Error';

import UserContext from 'context/UserContext';

export default function Scheduled({ itemID }) {
	const componentRef = useRef();
	const { headers } = useContext(UserContext);
	const [ tab, setTab ] = useState('overview');
	const [ view, setView ] = useState('large');
	const [ item, setItem ] = useState(itemID);

	const { data, loading, error } = useQuery(SUBSCRIPTION_DETAILS, {
		variables: { id: itemID }
	});

	const [ charge, { data: chargeDate, loading: chargeLoading, error: chargeError } ] = useMutation(CHARGE, {
		variables: { subscriptionID: itemID },

		refetchQueries: [ 'Account', 'Subscriptions', 'SubscriptionDetails' ]
	});

	const [ skip, { data: skipData, loading: skipLoading, error: skipError } ] = useMutation(SKIP, {
		variables: { subscriptionID: itemID },

		refetchQueries: [ 'Account', 'Subscriptions', 'SubscriptionDetails' ]
	});

	const [ pause, { data: pauseData, loading: pauseLoading, error: pauseError } ] = useMutation(PAUSE, {
		variables: { subscriptionID: itemID },

		refetchQueries: [ 'Account', 'Subscriptions', 'SubscriptionDetails' ]
	});

	const [ resume, { data: resumeData, loading: resumeLoading, error: resumeError } ] = useMutation(RESUME, {
		variables: { subscriptionID: itemID },
		refetchQueries: [ 'Account', 'Subscriptions', 'SubscriptionDetails' ]
	});

	const [ cancel, { data: cancelData, loading: cancelLoading, error: cancelError } ] = useMutation(CANCEL, {
		variables: { subscriptionID: itemID },
		refetchQueries: [ 'Account', 'Subscriptions', 'SubscriptionDetails' ]
	});

	const [ showEditModal, hideEditModal ] = useModal(
		() => (
			<div className="modal is-active">
				<div className="modal-background" />
				<div className="modal-content">
					<ScheduleForm item={data.subscription} onClose={hideEditModal} />
				</div>
			</div>
		),
		[ data ]
	);

	if (loading) return <Spinner />;
	if (error) return <Error error={error} />;

	const {
		amount,
		consumer,
		payments,
		lastRun,
		nextRun,
		description,
		startDate,
		status,
		frequency
	} = data.subscription;

	const buttons = [
		{
			action: charge,
			icon: 'credit-card',
			text: 'Charge now',
			color: 'primary',
			loading: chargeLoading,
			disabled: status === 'canceled'
		},
		{
			action: skip,
			icon: 'forward',
			text: 'Skip next charge',
			color: 'warning',
			loading: skipLoading,
			disabled: status === 'canceled'
		},
		{
			action: showEditModal,
			icon: 'wrench',
			text: 'Edit',
			color: 'dark',
			loading: resumeLoading,
			disabled: status === 'canceled'
		},
		status !== 'paused'
			? {
					action: pause,
					icon: 'pause',
					text: 'pause',
					color: 'warning',
					loading: pauseLoading,
					disabled: status === 'canceled'
				}
			: {
					action: resume,
					icon: 'play',
					text: 'resume',
					color: 'success',
					loading: resumeLoading,
					disabled: status === 'canceled'
				},
		{
			action: cancel,
			icon: 'times',
			text: 'Cancel',
			color: 'danger',
			loading: cancelLoading,
			disabled: status === 'canceled'
		}
	];

	return (
		<div>
			<Box>
				<article className={`message is-${setColor(status)}`}>
					<div className="message-body">
						<StatusTag type="scheduled" item={data.subscription} size="medium" />
						<br />
						<h1 className="title is-1">
							<small>$</small>
							{Number(amount).toFixed(2)}
						</h1>
						<h2 className="subtitle">{formatDate(nextRun)}</h2>
						<br />
					</div>
				</article>
				<Level>
					<Left>
						<Item>
							<Toolbar buttons={buttons} color="primary" size="large" />
						</Item>
					</Left>
					<Right>
						<Item>
							<ReactToPrint
								trigger={() => (
									<span className="button is-medium is-dark">
										<span className="icon">
											<i className="fas fa-print" />
										</span>
										<span>Print</span>
									</span>
								)}
								content={() => componentRef.current}
							/>
						</Item>
					</Right>
				</Level>
			</Box>
			<p className="title is-4">Transactions</p>
			<List type="activity" view="table" data={payments} actions={[ setItem ]} />
		</div>
	);
}

// *****************************************
// **************** GraphQL ****************
// *****************************************

const SUBSCRIPTION_DETAILS = gql`
	query SubscriptionDetails($id: ID!) {
		subscription(id: $id) {
			id
			description
			status
			frequency
			startDate
			amount
			currency
			nextRun
			lastRun
			consumer {
				id
				email
				firstName
				lastName
			}
			payments {
				id
				date
				amount
				status
				card {
					id
					brand
					last4
				}
				consumer {
					id
					email
					firstName
					lastName
				}
			}
		}
	}
`;

const RESUME = gql`
	mutation ResumeSubscription($subscriptionID: ID!) {
		resumeSubscription(subscriptionID: $subscriptionID) {
			id
			amount
			frequency
			status
		}
	}
`;

const PAUSE = gql`
	mutation PauseSubscription($subscriptionID: ID!) {
		pauseSubscription(subscriptionID: $subscriptionID) {
			id
			amount
			frequency
			status
		}
	}
`;

const SKIP = gql`
	mutation SkipNextRunSubscription($subscriptionID: ID!) {
		skipNextRunSubscription(subscriptionID: $subscriptionID) {
			id
			amount
			frequency
			status
		}
	}
`;

const CANCEL = gql`
	mutation CancelSubscription($subscriptionID: ID!) {
		cancelSubscription(subscriptionID: $subscriptionID) {
			id
			amount
			frequency
			status
		}
	}
`;

const CHARGE = gql`
	mutation ChargeSubscription($subscriptionID: ID!) {
		chargeSubscription(subscriptionID: $subscriptionID) {
			id
			amount
			frequency
			status
		}
	}
`;

const UPDATE = gql`
	mutation UpdateSubscription($subscriptionProps: SubscriptionProps) {
		updateSubscription(subscriptionProps: $subscriptionProps) {
			id
			status
		}
	}
`;
