const firebaseConfig = {
	apiKey: 'AIzaSyAzUBfFMePUlw1bY-F85nfMLrhbaWIt4tI',
	authDomain: 'cardql-dashboard.firebaseapp.com',
	databaseURL: 'https://cardql-dashboard.firebaseio.com',
	projectId: 'cardql-dashboard',
	storageBucket: 'cardql-dashboard.appspot.com',
	messagingSenderId: '917060098767',
	appId: '1:917060098767:web:4ba03f9421ad632622b5ba'
};

export default firebaseConfig;
