import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from '@reach/router';
import { useModal } from 'react-modal-hook';

import Spinner from 'components/ui/Spinner';

import Activate from 'components/forms/Activate';
import CustomerForm from 'components/forms/CustomerForm';
import InvoiceForm from 'components/forms/InvoiceForm';
import PaymentForm from 'components/forms/PaymentForm';
import ScheduleForm from 'components/forms/ScheduleForm';

import { firebaseAppAuth } from 'services/firebase';

import UserContext from 'context/UserContext';

import logo from 'assets/images/logo.png';

const MERCHANT = gql`
	query Merchant($id: ID!) {
		merchant(id: $id) {
			id
			name
			status
			isLive
		}
	}
`;

export default function Navbar({ extendedMenu, extendMenu }) {
	const { headers, merchant: { id }, user } = useContext(UserContext);
	const { loading, data, error } = useQuery(MERCHANT, {
		variables: { id },

	});

	const [ showActivate, hideActivate ] = useModal(() => (
		<div className="modal is-active">
			<div className="modal-background" />
			<div className="modal-content">
				<Activate onClose={hideActivate} />
			</div>
		</div>
	));

	const [ showPaymentFormModal, hidePaymentFormModal ] = useModal(() => (
		<div className="modal is-active">
			<div className="modal-background" />
			<div className="modal-content">
				<PaymentForm onClose={hidePaymentFormModal} />
			</div>
		</div>
	));

	const [ showCreateScheduledModal, hideCreateScheduledModal ] = useModal(() => (
		<div className="modal is-active">
			<div className="modal-background" />
			<div className="modal-content">
				<ScheduleForm onClose={hideCreateScheduledModal} />
			</div>
		</div>
	));

	const [ showInvoiceForm, hideInvoiceForm ] = useModal(() => (
		<div className="modal is-active">
			<div className="modal-background" />
			<div className="modal-content">
				<InvoiceForm onClose={hideInvoiceForm} />
			</div>
		</div>
	));

	const [ showCustomerForm, hideCustomerForm ] = useModal(() => (
		<div className="modal is-active">
			<div className="modal-background" />
			<div className="modal-content">
				<CustomerForm onClose={hideCustomerForm} />
			</div>
		</div>
	));

	if (loading || !data) return null;
	if (error) return <div>{error}</div>;

	if(data?.merchant){
	const { merchant, merchant: { name, isLive, status } } = data;

	return (
		<>
		<nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
			<div className="navbar-brand">
				<div className="navbar-item">

						<span className="icon is-large has-text-light" onClick={() => extendMenu(!extendedMenu)}>
							<span className="fa-stack fa-lg">
								<i className="fas fa-square fa-stack-2x" />
								<i className="fas fa-bars fa-stack-1x has-text-grey" />
							</span>
						</span>

					<Link className="navbar-item" to="/">
							{/* <img alt="logo" src={logo} /> */}
							<p className="title">CardQL</p>
					</Link>
				</div>
			</div>

			<div id="navbarBasicExample" className="navbar-menu">
				<div className="navbar-start">
					<div className="navbar-item">
						<Link to="/">
							<p className="title is-4">{name ? name : 'New merchant'}</p>
						</Link>
					</div>
						{merchant.status !== 'new' && (
							<div className="navbar-item">
								<strong>
									{isLive ? (
										<span className="tag is-success">Live</span>
									) : status === 'new' || status === 'onboarding' ? (
										<span className="button is-danger is-small" onClick={showActivate}>
											Activate now
										</span>
									) : (
												<span className="tag is-warning is-small">In Review</span>
											)}
								</strong>
							</div>)}


				</div>
					{merchant.status !== 'new' && (
						<div id="navbarBasicExample" className="navbar-menu">
							<div className="navbar-end">


								<div className="navbar-item">
									<div className="dropdown is-hoverable is-right">
										<div className="dropdown-trigger">
											<button
												className="button is-medium is-primary"
												aria-haspopup="true"
												aria-controls="dropdown-menu"
											>
												<span className="icon">
													<i className="fas fa-plus-circle" aria-hidden="true" />
												</span>
												<span>
													<strong>Create</strong>
												</span>
												<span className="icon is-small">
													<i className="fas fa-angle-down" aria-hidden="true" />
												</span>
											</button>
										</div>
										<div className="dropdown-menu" id="dropdown-menu" role="menu">
											<div className="dropdown-content">
												<a className="dropdown-item" onClick={showPaymentFormModal}>
													<span className="icon is-large">
														<i className="fas fa-credit-card fa-lg" />
													</span>
													<span className="title is-6">Manual Payment</span>
												</a>
												<hr className="dropdown-divider" />
												<a className="dropdown-item" onClick={showInvoiceForm}>
													<span className="icon is-large">
														<i className="fas fa-paperclip fa-lg" />
													</span>
													<span className="title is-6">Invoice</span>
												</a>
												<a className="dropdown-item" onClick={showCreateScheduledModal}>
													<span className="icon is-large">
														<i className="fas fa-clock fa-lg" />
													</span>
													<span className="title is-6">Scheduled Payment</span>
												</a>
												<a className="dropdown-item has-text-grey">
													<span className="icon is-large">
														<i className="fas fa-tasks fa-lg" />
													</span>
													<span className="title is-6 has-text-grey">
														Installment Plan <span className="tag is-info">Coming soon</span>
													</span>
												</a>
												<a className="dropdown-item has-text-grey">
													<span className="icon is-large">
														<i className="fas fa-link fa-lg" />
													</span>
													<span className="title is-6 has-text-grey">
														Payment Link <span className="tag is-info">Coming soon</span>
													</span>
												</a>
												<a className="dropdown-item has-text-grey">
													<span className="icon is-large">
														<i className="fas fa-qrcode fa-lg" />
													</span>
													<span className="title is-6 has-text-grey">
														QR Code <span className="tag is-info">Coming soon</span>
													</span>
												</a>
												<hr className="dropdown-divider" />
												<a className="dropdown-item" onClick={showCustomerForm}>
													<span className="icon is-large">
														<i className="fas fa-address-card fa-lg" />
													</span>
													<span className="title is-6">Customer</span>
												</a>
											</div>
										</div>
									</div>
								</div>

								<div className="navbar-item">
									<div
										className="dropdown is-hoverable is-right"
										aria-haspopup="true"
										aria-controls="dropdown-menu4"
									>
										<span className="icon dropdown-trigger">
											<span className="icon is-large" aria-hidden="true">
												<span className="fa-stack fa-lg has-text-grey">
													<i className="fas fa-circle fa-stack-2x" />
													<i className="fas fa-user-ninja fa-stack-1x fa-inverse" />
												</span>
											</span>
											<i className="fas fa-angle-down" aria-hidden="true" />
										</span>

										<div className="dropdown-menu" id="dropdown-menu4" role="menu">
											<div className="dropdown-content">
												{/* <a className="dropdown-item">Notifications</a>
										<a className="dropdown-item">Settings</a> */}
												<div className="dropdown-item">
													<p>
														<small>
															<strong>{user.email}</strong>
														</small>
													</p>
													<p>
														<small>
															<strong>Last login:</strong> {user.metadata.lastSignInTime}
														</small>
													</p>
												</div>
												<hr className="dropdown-divider" />
												<a className="dropdown-item" onClick={() => firebaseAppAuth.signOut()}>
													<strong>Log Out</strong>
												</a>
											</div>
										</div>
									</div>
								</div>

								<div className="navbar-item"> </div>
							</div>
						</div>)}
			</div>
		</nav>

		{/* BOTTOM NAVBAR */}

			{/* <nav className="navbar is-dark is-fixed-bottom is-hidden-desktop is-hidden-tablet" role="navigation" aria-label="main-navigation">

					<div className="navbar-brand">
							<div className="navbar-item">
							<div className="dropdown is-hoverable is-up">
								<div className="dropdown-trigger">
									<button
										className="button is-medium is-primary"
										aria-haspopup="true"
										aria-controls="dropdown-menu"
									>
										<span className="icon">
											<i className="fas fa-plus-circle" aria-hidden="true" />
										</span>
										<span>
											<strong>Create</strong>
										</span>
										<span className="icon is-small">
											<i className="fas fa-angle-up" aria-hidden="true" />
										</span>
									</button>
								</div>
								<div className="dropdown-menu" id="dropdown-menu" role="menu">
									<div className="dropdown-content">
										<a className="dropdown-item" onClick={showPaymentFormModal}>
											<span className="icon is-large">
												<i className="fas fa-credit-card fa-lg" />
											</span>
											<span className="title is-6">Manual Payment</span>
										</a>
										<hr className="dropdown-divider" />
										<a className="dropdown-item" onClick={showInvoiceForm}>
											<span className="icon is-large">
												<i className="fas fa-paperclip fa-lg" />
											</span>
											<span className="title is-6">Invoice</span>
										</a>
										<a className="dropdown-item" onClick={showCreateScheduledModal}>
											<span className="icon is-large">
												<i className="fas fa-clock fa-lg" />
											</span>
											<span className="title is-6">Scheduled Payment</span>
										</a>
										<a className="dropdown-item has-text-grey">
											<span className="icon is-large">
												<i className="fas fa-tasks fa-lg" />
											</span>
											<span className="title is-6 has-text-grey">
												Installment Plan <span className="tag is-info">Coming soon</span>
											</span>
										</a>
										<a className="dropdown-item has-text-grey">
											<span className="icon is-large">
												<i className="fas fa-link fa-lg" />
											</span>
											<span className="title is-6 has-text-grey">
												Payment Link <span className="tag is-info">Coming soon</span>
											</span>
										</a>
										<a className="dropdown-item has-text-grey">
											<span className="icon is-large">
												<i className="fas fa-qrcode fa-lg" />
											</span>
											<span className="title is-6 has-text-grey">
												QR Code <span className="tag is-info">Coming soon</span>
											</span>
										</a>
										<hr className="dropdown-divider" />
										<a className="dropdown-item" onClick={showCustomerForm}>
											<span className="icon is-large">
												<i className="fas fa-address-card fa-lg" />
											</span>
											<span className="title is-6">Customer</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>



			</nav> */}

</>
	);}
}
